import React, { useState } from 'react';
import axios from 'axios';

const RequestForm = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/mailer.php', formData)
      .then(response => {
        console.log('Сообщение отправлено', response.data);
        onClose();
      })
      .catch(error => {
        console.error('Ошибка при отправке сообщения', error);
      });
  };

  if (!isVisible) return null;

  return (
    <div className="request-form-overlay">
      <div className="request-form">
        <button onClick={onClose} className="close-button">X</button>
        <h2>Отправить заявку</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Имя:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required />
          </label>
          <label>
            Телефон:
            <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
          </label>
          <label>
            Сообщение:
            <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
          </label>
          <button type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default RequestForm;
